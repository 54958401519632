.custom-textarea {
  border: none !important;
  height: 90vh;
  resize: none;
  background-color: transparent;
  display: block;
  width: 100%; 
}

.toolbar {
  width: 100%; /* Full width of the wrapper */
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: flex-start; /* Align items to the left */
  background-color: transparent;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 10px; /* Space between the toolbar and the textarea */
  gap: 10px; /* Space between toolbar items */
}

.toolbar select {
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  width: auto; /* Set width to auto to match content width */
  min-width: 100px; /* Minimum width for dropdown */
  max-width: 200px; /* Maximum width for dropdown */
}

.toolbar button {
  padding: 10px;
  /* border: 1px solid #d4d4d4; */
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  border-radius: 5px;
  z-index: 99;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional: Add a shadow for better visibility */
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #d4d4d4;
  background-color: white;
}

.autocomplete-items div.active {
  background-color: #d4d4d4; /* Highlight color for the selected item */
}

.autocomplete-items div:hover {
  background-color: #f0f0f0; /* Hover color */
}

.autocomplete-items div:last-child {
  border-bottom: none; /* Remove border from the last item */
}


input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 2px solid #ddd;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline:none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 2rem;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.number-input button:after {
  display: inline-block;
  position: absolute;
  font-family: "Font Awesome 5 Free"; 
  font-weight: 900;
  content: '\f077';
  transform: translate(-50%, -50%) rotate(180deg);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.number-input input[type=number] {
  font-family: sans-serif;
  max-width: 5rem;
  padding: .2rem;
  border: solid #ddd;
  border-width: 0 2px;
  font-size: 14px;
  height: 2rem;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .adsbygoogle {
    display: none;
  }
}


